import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';

import Brands from './components/Brands/Brands';
import InfoBox1 from './components/InfoBox1/InfoBox1';
import InfoBox2 from './components/InfoBox2/InfoBox2';


import QuizPage from './components/Quiz/QuizPage';
import ReverseBrand from './components/ReverseBrand/ReverseBrand';
import Header from './components/header/Header';
import MainTitle from './components/maintitle/MainTitle';
import Why from './components/why/Why';
import Dashboard from './components/Dashboard/Dashboard';
import Footer from './components/Footer/Footer';
import GoldComponent from './components/Gold/GoldComponent';
import Homepage from './Pages/Homepage';
import Privacy from './components/Privacy/Privacy';
import NotFound from './components/NotFound/NotFound';
import Terms from './components/Terms/Terms';
import AboutUs from './components/AboutUs/AboutUs';
import ContactUs from './components/ContactUs';


function App() {

  return (
    <div className="App">
      <Routes>

        <Route path="/" element={<Homepage />} />
        <Route path="/gold" element={<GoldComponent />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs/>}/>
        <Route path="*" element={<NotFound />} />

      </Routes>
    </div>
  );
}

export default App;

import React from 'react';
import { Trophy, LockKeyhole, Banknote } from 'lucide-react';

const GoldInvestment: React.FC = () => {
  return (
    <section className="py-20 bg-gradient-to-b from-yellow-50 to-white">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl md:text-4xl font-bold mb-6 text-gray-800">
            Invest in Digital Gold
          </h2>
          <p className="text-xl text-gray-600 mb-10 leading-relaxed">
            With the GoldBee app, you can participate in the tradition of saving in 24 Karat gold at 99.9% purity, and build wealth and security for you and your family.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-12">
          {[
            { title: "High Purity", description: "Invest in 24 Karat gold with 99.9% purity", icon: Trophy },
            { title: "Secure Storage", description: "Your gold is stored securely in bank-grade vaults", icon: LockKeyhole },
            { title: "Easy Liquidity", description: "Sell your gold anytime with ease", icon: Banknote }
          ].map((feature, index) => (
            <div
              key={index}
              className="bg-white rounded-lg p-6 shadow-lg hover:shadow-xl transition-shadow duration-300 border border-yellow-100"
            >
              <div className="text-4xl mb-4 text-yellow-500">
                {React.createElement(feature.icon, { size: 48 })}
              </div>
              <h3 className="text-xl font-semibold mb-2 text-gray-800">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>

        <div className="mt-16 text-center">
          <a 
            href="#invest-now" 
            className="inline-block bg-yellow-400 text-gray-900 font-bold py-3 px-8 rounded-full text-lg hover:bg-yellow-300 transition duration-300 ease-in-out transform hover:scale-105 shadow-md"
          >
            Start Investing Now
          </a>
        </div>
      </div>
    </section>
  );
};

export default GoldInvestment;


import React from 'react';
import './Privacy.css'

const Privacy = () => {
  return (
    <div>
      <h2>Privacy Policy</h2>
      <p>
        This privacy policy (Privacy Policy) explains our policy inter alia regarding the collection, use, disclosure, security and transfer of your information collected/stored by GoldBee and its subsidiary(ies) and/or affiliate(s) (collectively referred to as the "GoldBee") which operates the GoldBee mobile application platform (Platform) and other services including but not limited to real-time group-payment settlement system and other related services (collectively the "GoldBee Service" or "Services"). This Privacy Policy forms part and parcel of the Conditions of Use for the GoldBee Platform and Services. Capitalized terms which have been used here but are undefined shall have the same meaning as attributed to them in the Conditions of Use.
      </p>
      <p>
        By using the Platform, you agree to the terms of this Privacy Policy. Whenever you submit information or avail any Services on the Platform, you consent to the collection, use, and disclosure of the information in accordance with this Privacy Policy.
      </p>
      <p>
        We take the privacy of our users seriously. We are committed to reasonably safeguarding the privacy of our users while providing personalized and valuable Services.
      </p>
      <h3>Information Supplied by Users</h3>
      <p>
        To avail certain services on the Platform, users are required to provide some "Personal Information" for the registration process and creation of GoldBee Account which may include:
      </p>
      <ol>
        <li>Your Name</li>
        <li>Email Address</li>
        <li>Phone Number</li>
        <li>Access to Camera</li>
        <li>User Images</li>
        <li>Access to your phone's contact list</li>
        <li>Debit card, credit card or bank details (GoldBee doesn't store details)</li>
        <li>Surveys that you complete through the Website or Platform or based on our request</li>
        <li>Furnishing information for the purpose of Know-Your-Customer (KYC) including but not limited to personal identification number of AADHAR, PAN, voter identification etc.</li>
        <li>UPI ID</li>
        <li>Date of birth</li>
        <li>Picture for the display</li>
        <li>Any other such information as required.</li>
      </ol>
      <p>
        The Information as supplied by the users enables us to provide the Services, to improve the Platform, and to provide you the most user-friendly experience. All required Information is service dependent, and GoldBee may use the above said user Information to maintain, protect, and improve the Platform/Services and/or for developing new services.
      </p>
      <p>
        We may also use your phone number, email address, or other Personal Information to send commercial or marketing messages without your consent [with an option to unsubscribe (wherever feasible)]. Furthermore, we may, however, use your email address and phone number without consent for non-marketing or administrative purposes (such as notifying you of major changes, for customer service purposes, providing information about updates to Platform Services, etc.).
      </p>
      <p>
        Any Personal Information provided by you will not be considered as sensitive if it is freely available and/or accessible in the public domain. Further, any reviews, comments, messages, blogs posted/uploaded/conveyed/communicated by users on the public sections of the Social Media sites or an Application Store (like the App Store or Play Store) becomes published content and is not considered Personal Information subject to this Privacy Policy.
      </p>
      <p>
        In case you choose to decline to submit Personal Information on the Platform, we may not be able to provide certain services, if not all, on the Platform to you. We may make reasonable efforts to notify you of the same at the appropriate time. In any case, we will not be liable and/or responsible for the denial of certain services to you for the lack of you providing the necessary Information.
      </p>
      <p>
        When you register with the GoldBee Account on the Platform, we may contact you from time to time about updates of your Personal Information to provide such features that we believe may benefit/interest you.
      </p>
      <p>
        In order to fulfill our legal obligations to prevent fraud and money laundering, we may obtain information about you from third-party agencies ("Third Party Information"), including your financial history, court judgments and bankruptcies, from credit reference and fraud prevention agencies when you open a GoldBee account and at any time when we feel it is necessary to prevent fraud and minimize our financial risks. Please refer to Paragraph 6 (Anti-Money Laundering Policy) of this Privacy Policy for further details.
      </p>
      <h3>Information Automatically Tracked While Using the Platform</h3>
      <p>
        The Platform may read personal and OTP messages only for the purpose of providing better personalized Service. No personal SMSes or OTPs are backed up. The collection of such information is only limited to the extent that such data is available in the relevant messages. GoldBee may also record the identity of the product or service and the price or fee paid or payable in respect thereof. We may reference other sources of demographic and other information in order to provide you with more targeted communications and promotions. We may use Google Analytics, among other analytics and marketing tools, to track user behavior on our Platform to help us gain an understanding of our users' demographics and interests. These tools may collect and track app usage data and leverage user identifiable data for analytics and marketing purposes. Our servers automatically collect limited information about your device's connection to the Internet when you use the Platform. We automatically receive and log information from the Platform including but not limited to IP address, your device's name, and your operating system. We may also collect log information from your device, including but not limited to your location, IP address, your device's name, device's serial number or unique identification number, your device operating system, and version, CPU speed, and connection speed, etc.
      </p>
      <h3>Links to Third Party Sites</h3>
      <p>
        The Platform may include links to other websites/platforms. Such websites/platforms are governed by their respective privacy policies, which are beyond our control. Once you leave our servers, the use of any information you provide on such Third-Party websites/platforms is governed by the privacy policy of the operator of the websites/platforms you are visiting. That policy may differ from ours. If you cannot find the privacy policy of any of these websites/platforms via a link from the websites/platforms' homepage, you should contact the websites/platforms directly for more information. It is reiterated that GoldBee, for the purposes of payments and settlements, will direct the user to our registered Payment and Settlement Merchant. We do not store any information regarding payment details, including but not limited to Internet Banking, Debit or Credit Card details. All such information is submitted to such registered Payment and Settlement Merchant, and you are governed by the Terms and Conditions of Use and Privacy Policy of such merchant. GoldBee shall not be liable in any case of any loss of data, including financial credentials and its data, on such Third-Party vendors or merchants. In such an event, you will hold GoldBee harmless and innocent and indemnify GoldBee for all costs whatsoever.
      </p>
      <h3>Use and Storage of Information</h3>
      <p>
        We use your Information for the following purposes:
      </p>
      <ul>
        <li>To operate and administer your GoldBee account and to provide services that you have requested.</li>
        <li>To carry out your instructions to make and/or receive payments and undertake transactions using our Services.</li>
        <li>To allow you to participate in interactive features of the Platform.</li>
        <li>To notify you about changes to our service(s).</li>
        <li>To improve our internal customer training.</li>
        <li>To comply with financial services regulations.</li>
        <li>For financial and identity checks, fraud prevention checks, anti-money laundering and credit checks.</li>
        <li>For customer service, including answering questions and responding to feedback and complaints.</li>
        <li>To enhance the security of our Services.</li>
        <li>To ensure that content on our Platform is presented in the most effective manner for you and for your device.</li>
        <li>To provide you with information, products, or services that you request from us or which we feel may interest you.</li>
      </ul>
      <p>
        Retention of Data: We are required under applicable laws to retain certain records for a certain period of time after the closure of your GoldBee account, which will include your personal data such as your name, contact details, customer number, and transaction history, etc. ("Retained Data"). Other than the Retained Data, we will delete and destroy all Personal Information that we hold about you when you (or we) terminate your GoldBee account. Under no circumstances, we store your payment credentials.
      </p>
      <p>
        Please note that we will never ask you to disclose your personal or security details by email. If you receive any email purportedly from GoldBee requesting your personal or security details, please do not respond to it (Such Email). Please forward any Such Email to us at admin@goldbee.in and thereafter delete the email immediately.
      </p>
      <h3>Disclosure of Information</h3>
      <p>
        We do not disclose your Information to any third parties other than the GoldBee affiliates and the following:
      </p>
      <ul>
        <li>A prospective buyer of our business or a buyer of a substantial number of the shares in our business.</li>
        <li>The police, other lawful enforcement body, regulatory body or Hon'ble Court if we are under a duty or required by law to disclose or share your personal data, or to protect the rights, property, or safety of ourselves or our group companies, our customers, or others.</li>
        <li>Financial institutions with whom we partner to jointly provide better services, facilities, or products to you. These financial institutions may only use your information for providing you their services and market GoldBee-related products without requiring your further consent.</li>
        <li>Third parties where you have expressed an interest in receiving information about their goods and/or services.</li>
        <li>Third parties who referred you to us initially and to whom we owe a commission payment as a result of the referral. Where the commission payment is based on transaction volumes, numbers, or types of transactions, we may share that information with that third party, but we will not disclose the full details of each of your transactions without your further written consent.</li>
        <li>Third parties we may occasionally use to provide you with the services that you have requested. We require these third parties to not use your personal information for any other purpose.</li>
      </ul>
      <p>
        We also use Information in aggregate form (so that no individual user is identified):
      </p>
      <ul>
        <li>To build up marketing profiles.</li>
        <li>To aid strategic development.</li>
        <li>To audit usage of the Platform.</li>
      </ul>
      <p>
        We reserve the right to disclose Information if required to do so by law or if we believe that it is necessary to do so to protect and defend the rights, property, or personal safety of the Platform, or its users.
      </p>
      <h3>Anti-Money Laundering Policy</h3>
      <p>
        In order to comply with anti-money laundering laws that exist in various jurisdictions, we reserve the right to report suspicious transactions to Central, State, provincial, or local authorities and law enforcement agencies within those jurisdictions. In exchange for us permitting you to participate in, use, and access our Platform and/or Services, you hereby grant us the right to report any transactions which we deem suspicious, as determined solely by GoldBee acting in its sole discretion. Examples of a suspicious transaction include but are not limited to use of funds suspected to be derived from illegal activities, any suspected intention to conceal or disguise funds derived from illegal activities, or suspicion that the involvement of our Platform and/or Services is in any manner intended to facilitate criminal activity.
      </p>
      <p>
        If for any reason we are of the belief or become aware of any transaction which we deem suspicious in nature, we may without prior notice or explanation to you take the following actions, which are in addition to all other rights and remedies available to us under this Privacy Policy, at law or in equity:
      </p>
      <ul>
        <li>Report such transaction to the applicable Central, State, provincial, or local authorities and law enforcement agencies.</li>
        <li>Deactivate or terminate your GoldBee account and Services forthwith.</li>
        <li>Restrict you from registering a GoldBee account on our Platform or with any of our affiliated Platforms/Business associates.</li>
      </ul>
      <h3>Communications</h3>
      <p>
        We may contact you via the email address and phone number registered with your GoldBee account. You may also receive system-generated transactional emails such as confirmations, notification of receipt of payments, notification of password changes, etc., which are necessary for the proper operation and administration of your GoldBee account. You expressly consent to receive such communications from us, irrespective of whether your phone number is registered on the National Do-Not-Call Registry or Do-Not-Disturb Registry. As a GoldBee account holder, you will occasionally receive information by email from us, unless you have expressly chosen not to receive such communication, about products, services, and special deals which we think will be of interest to you via our newsletter. You can change whether or not you wish to receive newsletters from us. However, please note that you will still receive communication regarding your GoldBee account, such as transactional emails or other notifications affecting the operation of your GoldBee account or our legal relationship.
      </p>
      <h3>Phishing</h3>
      <p>
        Phishing is the name given to attempts to steal personal details and financial account details from a Platform user. "Phishers" use fake or "spoof" emails to lead users to counterfeit websites where the user is tricked into entering their personal details, such as financial credentials, usernames, and passwords. If you receive such an email or are asked for your password by anyone claiming to work for us, please forward the email or report the incident by email to us at admin@goldbee.in.
      </p>
      <h3>User Choice</h3>
      <p>
        You may choose not to provide us with any Information while accessing the Platform. In such an event, you will not be able to access and use those portions of the Platform that require your Information.
      </p>
      <h3>Confidentiality and Security</h3>
      <p>
        Except as otherwise provided in this Privacy Policy, we will keep your Information private and will not share it with third parties unless we believe in good faith that disclosure of your Information or any other information we collect about you is necessary to comply with Hon'ble Court's order(s) or other legal process, protect the rights, property, or safety of GoldBee, its members, or another party, or enforce our Conditions of Use or respond to claims that any posting or other content violates the rights of third parties.
      </p>
      <h3>Information Storage and Backup</h3>
      <p>
        We take a backup of your data on the Platform on our Cloud database. This is done for the purpose of enabling users to get their data back in case their phone's data becomes unusable, if the phone is lost, or the user moves to a new phone device. We also use this backup to provide useful insights and information related to their spends, such as weekly spends in a month, insights related to spend behavior, etc., and to provide information related to user spends, patterns, and user data in the form of aggregated and anonymized statistics on data such as user spends by category, date, time, bank balances, etc.
      </p>
      <h3>Information Security</h3>
      <p>
        We take appropriate security measures to protect against unauthorized access to or unauthorized alteration, disclosure, or destruction of data. These include internal reviews of our data collection, storage, and processing practices and security measures, including appropriate encryption and physical security measures to guard against unauthorized access to systems where we store personal data. All information gathered on the GoldBee Platform is securely stored within the controlled database. Access to the servers is password-protected and is strictly limited. We use standard authentication using a mobile number and OTP to issue JWT tokens to our users for establishing a secure connection. All the user data is transmitted via this secure connection. For other third-party services, we use authentication as requested by that third-party service provider. Although we make good faith efforts to store Information in a secure operating environment that is not open to the public, you should understand that there is no such thing as complete security, and we do not guarantee that there will be no unintended disclosures of your Information. If we become aware that your Information has been disclosed in a manner not in accordance with this Privacy Policy, we will use reasonable efforts to notify you of the nature and extent of the disclosure (to the extent we know that information) as soon as reasonably possible and as permitted by law.
      </p>
      <h3>Updates/Changes</h3>
      <p>
        We may alter our privacy policy from time to time to incorporate necessary changes in technology, applicable laws, or any other variant. In any case, we reserve the right to change (at any point in time) the terms of this Privacy Policy or the Conditions of Use. Any changes we make will be effective immediately. Your use of the Platform and/or Services after such notice will be deemed acceptance of such changes. We may also make reasonable efforts to inform you via electronic mail. In any case, you are advised to review this Privacy Policy periodically to ensure that you are aware of the latest version.
      </p>
      <h3>Your Rights</h3>
      <p>
        You have certain rights under applicable law concerning your personal data. These rights may include the right to access and update your personal data, the right to request the erasure of your personal data, the right to restrict the processing of your personal data, and the right to data portability. To exercise these rights, please contact us using the contact details provided below. Please note that we may require additional information from you in order to authenticate your request. We will respond to your request in accordance with applicable law. If you have any concerns or complaints about our processing of your personal data, you have the right to lodge a complaint with the supervisory authority.
      </p>
      <h3>Contact Us</h3>
      <p>
        If you have any questions, comments, or concerns regarding this Privacy Policy or our practices, please send an email to admin@goldbee.in.
      </p>
    </div>
  );
};

export default Privacy;

import React from 'react';
import './AboutUs.css'; // Import the CSS file for styling

const AboutUs = () => {
    return (
        <div className="about-us-container">
            <h2 className="about-us-heading">Welcome to GoldBee</h2>
            <div className="about-us-content">
                <p>
                    At GoldBee, we've revolutionized the way you approach your finances. Our main focus is to turn your everyday spendings into investments effortlessly. We understand that your dreams and ambitions require financial stability, and we're here to make that journey easier for you.
                </p>
                <p>
                    Our unique approach centers around converting your spendings into investments seamlessly. We believe that every purchase you make should contribute towards your financial future. With GoldBee, you can start building your wealth with the same money you spend on your daily needs.
                </p>
                <p>
                    Imagine every swipe of your card or every purchase online as a step towards a brighter financial future. That's the power of GoldBee. We make saving and investing a passive experience, so you can focus on what truly matters to you.
                </p>
                <p>
                    Join us at GoldBee and experience the transformation of your spendings into investments. Let us help you pave the way for a more secure and prosperous tomorrow.
                </p>
            </div>
        </div>
    );
};

export default AboutUs;

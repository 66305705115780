import React from 'react';
import { Mail, Phone } from 'lucide-react';

const ContactUs: React.FC = () => {
  return (
    <section className="py-16 bg-gradient-to-b from-yellow-50 to-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-8 text-gray-800">
          Contact Us
        </h2>
        <p className="text-center text-gray-600 mb-12 max-w-2xl mx-auto">
          Have any questions or concerns? We're here to help. Reach out to us through any of the following methods:
        </p>
        <div className="flex flex-col md:flex-row justify-center items-center space-y-6 md:space-y-0 md:space-x-12">
          <a 
            href="mailto:admin@goldbee.in" 
            className="flex items-center space-x-3 text-gray-700 hover:text-yellow-600 transition-colors duration-300"
          >
            <div className="bg-yellow-100 p-3 rounded-full">
              <Mail className="w-6 h-6 text-yellow-600" />
            </div>
            <span className="text-lg">admin@goldbee.in</span>
          </a>
          <a 
            href="tel:+918801648801" 
            className="flex items-center space-x-3 text-gray-700 hover:text-yellow-600 transition-colors duration-300"
          >
            <div className="bg-yellow-100 p-3 rounded-full">
              <Phone className="w-6 h-6 text-yellow-600" />
            </div>
            <span className="text-lg">+91 8801648801</span>
          </a>
        </div>
        <div className="mt-12 text-center">
          <p className="text-gray-600">
            You can also reach us on WhatsApp at the number above.
          </p>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;


import React from 'react';
import './MainTitle.css';

export default function MainTitle() {
  return (
    <div className="bg-black min-h-[80vh] text-white py-20 px-4 sm:px-6 lg:px-8 relative overflow-hidden">
      <div className="max-w-7xl mx-auto relative z-10">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          {/* Left Column - Text Content */}
          <div className="space-y-8 text-left">
            <h1 className="text-4xl sm:text-5xl lg:text-6xl font-extrabold leading-tight bg-clip-text text-transparent bg-gradient-to-r from-yellow-400 to-yellow-200">
            Earn Real 24K Gold for Every Spend
            </h1>
            <p className="text-xl sm:text-2xl text-gray-300">
            Every purchase you make earns you free 24K Digital Gold from your favorite brands, turning your spending into an investment.
            </p>
            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
              <a 
                href="https://play.google.com/store/apps/details?id=com.goldbee_app&hl=en_IN" 
                target="_blank" 
                rel="noopener noreferrer"
                className="transition-transform duration-300 ease-in-out transform hover:scale-105 inline-block"
              >
                <img 
                  src="/google-play-badge.png" 
                  alt="Get it on Google Play" 
                  className="h-14 w-auto object-contain"
                />
              </a>
              <a 
                href="https://apps.apple.com/app/id/your.app.id" 
                target="_blank" 
                rel="noopener noreferrer"
                className="transition-transform duration-300 ease-in-out transform hover:scale-105 inline-block"
              >
                <img 
                  src="/app-store-badge.png" 
                  alt="Download on the App Store" 
                  className="h-14 w-auto object-contain"
                />
              </a>
            </div>
          </div>

          {/* Right Column - Card Display */}
          <div className="relative h-[600px] flex items-center justify-center">
            <div className="absolute w-[320px] h-[500px] animate-float">
              {/* Card Container with 3D effect */}
              <div className="relative w-full h-full transform transition-transform duration-1000 hover:rotate-y-12 preserve-3d">
                {/* Card Front */}
                <div className="absolute w-full h-full rounded-3xl shadow-2xl overflow-hidden">
                  <img 
                    src="/prepaidcard.png" 
                    alt="GoldBee Prepaid Card" 
                    className="w-full h-full object-cover"
                  />
                  {/* Gradient Overlay */}
                  <div className="absolute inset-0 bg-gradient-to-br from-black/20 to-transparent"></div>
                </div>
                
                {/* Glare Effect */}
                <div className="absolute inset-0 rounded-3xl bg-gradient-to-tr from-transparent via-white/5 to-transparent opacity-0 hover:opacity-100 transition-opacity duration-300"></div>
              </div>
            </div>

            {/* Background Effects */}
            <div className="absolute -top-20 -right-20 w-64 h-64 bg-yellow-400/20 rounded-full mix-blend-multiply filter blur-3xl animate-blob"></div>
            <div className="absolute -bottom-20 -right-20 w-64 h-64 bg-yellow-200/20 rounded-full mix-blend-multiply filter blur-3xl animate-blob2"></div>
          </div>
        </div>
      </div>
    </div>
  );
}


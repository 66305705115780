import React from 'react';
import { Link } from 'react-router-dom';

export default function Header() {
  return (
    <header className="bg-black text-white py-4 px-6 fixed w-full z-10">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/" className="text-2xl font-bold">
          <span className="bg-gradient-to-r from-yellow-400 to-yellow-200 bg-clip-text text-transparent">
            GoldBee
          </span>
        </Link>
        <nav>
          <ul className="flex space-x-6 text-sm">
            <li>
              <Link to="/gold" className="hover:text-yellow-400 transition-colors duration-200">
                Digital Gold
              </Link>
            </li>
            <li>
              <Link to="/about" className="hover:text-yellow-400 transition-colors duration-200">
                About
              </Link>
            </li>
            <li>
              <Link to="/contact" className="hover:text-yellow-400 transition-colors duration-200">
                Contact
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}


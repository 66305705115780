import React, { useState } from 'react';

const GoldComponent = () => {
  const [selectedOption, setSelectedOption] = useState('Rupees');
  const [amount, setAmount] = useState('');

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleAmountSelection = (selectedAmount) => {
    setAmount(selectedAmount);
  };

  const handleBuyGold = () => {
    // Handle buy gold logic here
    console.log('Buy gold clicked');
  };

  return (
    <div
      style={{
        width: '30%',
        height: '40%',
        backgroundColor: '#f2f2f2',
        padding: '20px',
      }}
    >
      {/* First row */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: '#e6e6e6',
          padding: '10px',
          marginBottom: '10px',
        }}
      >
        <button>Buy 24k digital gold</button>
        <button>Sell gold</button>
      </div>

      {/* Second row */}
      <div style={{ marginBottom: '10px' }}>
        <label htmlFor="buy-in">Buy in:</label>
        <select id="buy-in" value={selectedOption} onChange={handleOptionChange}>
          <option value="Rupees">Rupees</option>
          <option value="Grams">Grams</option>
        </select>
      </div>

      {/* Third row */}
      <div style={{ marginBottom: '10px' }}>
        <label htmlFor="amount">Amount:</label>
        <input
          type="text"
          id="amount"
          value={amount}
          onChange={handleAmountChange}
        />
      </div>

      {/* Fourth row */}
      <div>
        <label>Ready-made Amount:</label>
        <div>
          <button onClick={() => handleAmountSelection('500')}>500</button>
          <button onClick={() => handleAmountSelection('1000')}>1000</button>
          <button onClick={() => handleAmountSelection('2000')}>2000</button>
        </div>
      </div>

      {/* Buy gold button */}
      <button onClick={handleBuyGold}>Buy Gold</button>
    </div>
  );
};

export default GoldComponent;

import React from 'react';
import { motion } from 'framer-motion';
import { Plus } from 'lucide-react';

const brands = [
  { image: 'https://blog.myntra.com/wp-content/themes/myntra/assets/img/Myntra-logo-horizontal.png', rewards: '8% Gold Savings', name: 'Myntra' },
  { image: 'https://res.cloudinary.com/ole/image/upload/v1709879181/amazon_logo._CB633266945_.png', rewards: '4% Gold Savings', name: 'Amazon' },
  { image: 'https://res.cloudinary.com/ole/image/upload/v1707016358/swiggy_money.jpg', rewards: '4% Gold Savings', name: 'Swiggy' },
  { image: 'https://res.cloudinary.com/ole/image/upload/v1707038012/reliance-trendz.png', rewards: '12% Gold Savings', name: 'Reliance Trends' },
  { image: 'https://res.cloudinary.com/ole/image/upload/v1731670542/Logo_of_Zepto.png', rewards: '4% Gold Savings', name: 'Zepto' },
  { image: 'https://res.cloudinary.com/ole/image/upload/v1707038297/Zomato_Logo.jpg', rewards: '6% Gold Savings', name: 'Zomato' },
  { image: 'https://res.cloudinary.com/ole/image/upload/v1707283311/download.png', rewards: 'Earn 5%', name: 'Uber' }
];

const Brands: React.FC = () => {
  return (
    <section className="py-16 bg-black">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-12 text-white">
          Top Brands
        </h2>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
          {brands.map((brand, index) => (
            <motion.div
              key={index}
              className="bg-gray-900 rounded-lg overflow-hidden shadow-lg transition-shadow hover:shadow-2xl"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <div className="p-6 flex flex-col items-center">
                <div className="w-full h-24 mb-4 relative flex items-center justify-center">
                  <img
                    src={brand.image}
                    alt={`${brand.name} logo`}
                    className="max-w-full max-h-full object-contain"
                  />
                </div>
                <h3 className="text-xl font-semibold text-white mb-2 text-center">{brand.name}</h3>
                <p className="text-yellow-400 font-medium text-sm text-center">{brand.rewards}</p>
              </div>
            </motion.div>
          ))}
          
          {/* Additional card for 400+ brands */}
          <motion.div
            className="bg-gradient-to-br from-purple-600 to-blue-600 rounded-lg overflow-hidden shadow-lg transition-shadow hover:shadow-2xl"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <div className="p-6 flex flex-col items-center justify-center h-full">
              <div className="w-16 h-16 mb-4 bg-white/20 rounded-full flex items-center justify-center">
                <Plus className="w-8 h-8 text-white" />
              </div>
              <h3 className="text-2xl font-bold text-white mb-2 text-center">400+</h3>
              <p className="text-white/90 text-lg text-center">More Brands</p>
              <p className="text-yellow-400 font-medium text-sm mt-2 text-center"> Upto 20% discount</p>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Brands;


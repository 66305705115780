import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { ChevronDown, ChevronUp } from 'lucide-react';

const steps = [
  {
    logo: (
      <div className="text-2xl font-bold bg-gradient-to-r from-yellow-400 to-yellow-600 bg-clip-text text-transparent">
        GoldBee
      </div>
    ),
    title: "Add Money",
    description: "Funds go to a special YesBank collection account. GoldBee can't access this money - it's only for buying gold.",
    color: "bg-yellow-400"
  },
  {
    logo: (
      <img 
        src="https://res.cloudinary.com/ole/image/upload/v1732530085/augmont-logo.jpg" 
        alt="Augmont Logo" 
        className="h-12 object-contain"
      />
    ),
    title: "Gold Partner",
    description: "Augmont(Goldbee gold partner), India's top gold refinery, buys gold with your funds on your name.",
    color: "bg-blue-500"
  },
  {
    logo: (
      <img 
        src="https://res.cloudinary.com/ole/image/upload/v1732530246/Sequel-Courier-Tracking.webp" 
        alt="Sequel Logo" 
        className="h-12 object-contain"
      />
    ),
    title: "Secure Storage",
    description: "Your gold is stored in Sequel's SEBI-approved secure vault.",
    color: "bg-green-500"
  },
  {
    logo: (
      <img 
        src="https://res.cloudinary.com/ole/image/upload/v1732530207/png-clipart-logo-brand-public-relations-product-icici-lombard-travel-insurance-text-public-relations-removebg-preview-768x207.png" 
        alt="ICICI Lombard Logo" 
        className="h-12 object-contain"
      />
    ),
    title: "Insured Protection",
    description: "ICICI Lombard insures all the gold for complete security.",
    color: "bg-purple-500"
  }
];

const faqs = [
  {
    question: "What if GoldBee shuts down?",
    answer: "In the worst case scenario, if GoldBee shuts down, your investments are still safe. We are just a tech facilitator; your money or gold are not stored with us. You can still access your gold from our Gold partner, Augmont."
  },
  {
    question: "What if Augmont shuts down?",
    answer: "Augmont is a very large entity, one of India's largest gold refineries, and is unlikely to shut down suddenly. Even if Augmont were to shut down, they don't have the authority to store gold themselves. After purchase, Augmont stores the gold in secure vaults (Sequel), which are very large security vaults. Your gold remains securely stored in Sequel Vaults."
  },
  {
    question: "What if something bad happens at the secure vault?",
    answer: "These are not small lockers, but very well-engineered and highly secured vaults. Even if something bad were to happen, every milligram of gold is insured by ICICI Lombard."
  },
  {
    question: "Why is the Gold Sell price lower than the Buy price? Are there any extra charges if we buy gold through GoldBee, like commissions?",
    answer: "There are no extra charges or commissions when you buy or sell gold from GoldBee. Gold is a commodity, and for every commodity, the buy and sell price will be different. This price difference is called the 'spread' and is common in all gold investments, including Sovereign Gold Bonds. In GoldBee, the spread is typically around 2-2.5%. To understand the impact: If you hold your gold for 2-3 years, this one-time cost translates to an annual expense of about 0.6-0.7%. Compared to gold's average annual returns of 14-15% over the last 3 years, this 0.6-0.7% spread is minimal."
  }
];

const HowItWorks = () => {
  const [openFAQ, setOpenFAQ] = useState(null);

  const toggleFAQ = (index) => {
    setOpenFAQ((prevOpenFAQ) => prevOpenFAQ === index ? null : index);
  };

  return (
    <section className="py-20 bg-gradient-to-b from-gray-50 to-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold mb-16 text-center text-gray-800">
          How It Works <span className="text-green-600">(100% Safe)</span>
        </h2>
        
        <div className="max-w-4xl mx-auto relative">
          {/* Vertical line */}
          <div className="absolute left-1/2 top-0 bottom-0 w-0.5 bg-gray-200 transform -translate-x-1/2"></div>
          
          {steps.map((step, index) => (
            <motion.div 
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
              className="relative mb-16 last:mb-0"
            >
              <div className="flex items-center mb-4">
                <div className="w-1/2 pr-8 flex justify-end">
                  <div className="w-24 h-16 flex items-center justify-center">
                    {step.logo}
                  </div>
                </div>
                <div className={`w-12 h-12 rounded-full ${step.color} flex items-center justify-center z-10 shadow-lg`}>
                  <span className="text-white font-bold">{index + 1}</span>
                </div>
                <div className="w-1/2 pl-8">
                  <h3 className="text-xl font-semibold mb-2 text-gray-800">{step.title}</h3>
                  <p className="text-gray-600 leading-relaxed">{step.description}</p>
                </div>
              </div>
              
              {index < steps.length - 1 && (
                <motion.div 
                  className="absolute left-1/2 transform -translate-x-1/2 z-10"
                  animate={{ y: [0, 10, 0] }}
                  transition={{ duration: 2, repeat: Infinity }}
                >
                  <svg className="w-6 h-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 14l-7 7m0 0l-7-7m7 7V3" />
                  </svg>
                </motion.div>
              )}
            </motion.div>
          ))}
        </div>

        <motion.div 
          className="mt-16 text-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.8 }}
        >
          <div className="inline-block bg-green-50 rounded-lg p-4 mb-8">
            <p className="text-lg text-green-800 font-medium">
              Your investment is protected at every step of the process
            </p>
          </div>
        </motion.div>

        {/* FAQ Section */}
        <div className="mt-16 max-w-3xl mx-auto">
          <h3 className="text-2xl font-bold mb-8 text-gray-800">Frequently Asked Questions</h3>
          {faqs.map((faq, index) => (
            <div key={index} className="mb-4 border-b border-gray-200 pb-4">
              <button
                className="flex justify-between items-center w-full text-left"
                onClick={() => toggleFAQ(index)}
              >
                <span className="text-lg font-medium text-gray-700">{faq.question}</span>
                {openFAQ === index ? (
                  <ChevronUp className="w-6 h-6 text-gray-500" />
                ) : (
                  <ChevronDown className="w-6 h-6 text-gray-500" />
                )}
              </button>
              {openFAQ === index && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  transition={{ duration: 0.3 }}
                  className="mt-2 text-gray-600 text-left"
                >
                  {faq.answer}
                </motion.div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;


import React from 'react'
import Header from '../components/header/Header'
import MainTitle from '../components/maintitle/MainTitle'
import Brands from '../components/Brands/Brands'
import ReverseBrand from '../components/ReverseBrand/ReverseBrand'
import Footer from '../components/Footer/Footer'
import GoldInvestment from '../components/GoldInvestment'
import HowItWorks from '../components/HowitWorks'
import GoldCoinFeature from '../components/GoldCoin'
import ContactUs from '../components/ContactUs'

const Homepage = () => {
  return (
    <div>
        <Header />
        <MainTitle />
        <Brands />
        <GoldInvestment />
        <GoldCoinFeature/>
        <HowItWorks />
        <ContactUs />
        <Footer />
    </div>
  )
}

export default Homepage
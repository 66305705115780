import React from 'react';
import { motion } from 'framer-motion';

const GoldCoinFeature: React.FC = () => {
  return (
    <section className="py-20 bg-gradient-to-b from-yellow-50 to-white overflow-hidden">
      <div className="container mx-auto px-4">
        <motion.h2 
          className="text-3xl md:text-4xl font-bold text-center mb-12 text-gray-800"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          Get Gold Delivered to Your Doorstep
        </motion.h2>
        
        <div className="flex flex-col items-center justify-center">
          <div className="relative w-80 h-80 mb-8">
            <motion.img 
              src="https://prod-augmontgold.s3.ap-south-1.amazonaws.com/products/1/gallery/3eb57c8409724cc500218a57e885d556.png"
              alt="Gold Coin"
              className="w-48 h-48 object-contain absolute top-0 left-0"
              animate={{ 
                opacity: [1, 0.5, 1],
                scale: [1, 1.1, 1],
                rotateY: [0, 180, 0]
              }}
              transition={{ 
                duration: 4,
                repeat: Infinity,
                repeatType: "reverse"
              }}
            />
            <motion.img 
              src="https://prod-augmontgold.s3.ap-south-1.amazonaws.com/products/1/gallery/30c8ebe7ca4ad1896986597ea2d8a162.jpg"
              alt="Gold Certificate"
              className="w-64 h-auto object-contain absolute bottom-0 right-0"
              animate={{ 
                opacity: [0.5, 1, 0.5],
                scale: [0.9, 1, 0.9],
                rotateY: [180, 0, 180]
              }}
              transition={{ 
                duration: 4,
                repeat: Infinity,
                repeatType: "reverse"
              }}
            />
          </div>
          
          <p className="text-lg text-gray-600 text-center mb-6 max-w-md">
            Convert your digital gold to 24k Gold coins. Delivery available for all pincodes in India
          </p>
          
          <motion.button 
            className="bg-yellow-500 text-white font-bold py-3 px-8 rounded-full text-lg hover:bg-yellow-400 transition duration-300 ease-in-out transform hover:scale-105 shadow-md"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Convert Now
          </motion.button>
        </div>
      </div>
    </section>
  );
};

export default GoldCoinFeature;

